import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Just1 from '../images/JUST1.jpg'
import Just2 from '../images/JUST2.jpg'
import Just3 from '../images/JUST3.jpg'
import Just4 from '../images/JUST4.jpg'
import JustLogo from '../images/JUSTLOGO.jpg'
// import podcast from '../images/podcast.jpg'

export default () => (
  <div id="app" className="workpage">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="Description" content="WRAL Digital Solutions Refresh JUST Podcast Page"></meta>
        <title>JUST Podcast | WRAL Digital Solutions</title>
        <link rel="canonical" href="https://www.wraldigitalsolutions.com/just-podcast/" />
      </Helmet>

      <PreHeader/>
      <Header/>

        <div className="company_heading">
          <span className="company_heading--name">JUST PODCAST + WRALDS</span>
          <h1 className="company_heading--tagline">
          Creating a place for people to listen to stories about communities rooted in justice
          </h1>
        </div>
        <img className="company_gallery--image" src = {Just1} alt = "JUST Podcast "/>
        <div className="company_grid">
          <div className="company_overview">
            <div className="company_overview--heading">OVERVIEW</div>
            <p className="company_overview--text">
            JUST, a Capitol Broadcasting Company Network podcast, tells stories
            about people working to build thriving communities rooted in justice.
            We partnered with ReCity and Coastal Credit Union to create a platform
            for people to say what they need to, with a promise to listen
            and a pledge to process.We helped JUST out with:
            </p>
            <ul className="company_overview--list">
              <li>Graphic Design</li>
              <li>Podcast Production</li>
              <li>Logo Design</li>
              <li>Creative Strategy</li>
              <li>Branded Content</li>
              <li>Owned and Operated Media Strategy and Implementation</li>
            </ul>
            <a id="listenHere" target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/show/1kzIUbIVQSvE6JzdS7GhAg">
              Listen Here
            </a>
            {/* <a href="https://open.spotify.com/show/1kzIUbIVQSvE6JzdS7GhAg" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image podcast" src = {podcast} alt ="JUST podcast listen here link"/>
            </a> */}
          </div>
          <img className="company_logo" src = {JustLogo} alt ="JUST Podcast service company logo"/>

          <div className="company_gallery">
            <img className="company_gallery--image three" src = {Just2} alt ="JUST Podcast "/>
            <img className="company_gallery--image one" src = {Just3} alt ="JUST Podcast "/>
            <img className="company_gallery--image one" src = {Just4} alt ="JUST Podcast "/>
          </div>
        </div>
    <Footer/>
  </div>
)
